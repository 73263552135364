import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import mitt from "mitt";
import i18n from "@/i18n";
const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;

loadFonts();

app.use(router).use(i18n).use(store).use(vuetify).mount("#app");
