import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/EventMaster-logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    dark: "",
    class: "header",
    app: "",
    color: "primary"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                src: _imports_0,
                class: _normalizeClass(["logo", 'logo-' + _ctx.type])
              }, null, 8, ["class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}