
import { defineComponent, ref } from "vue";
import useEmitter from "@/config/bus";

export default defineComponent({
  setup() {
    const snackbar = ref(false);
    const snackbarMessage = ref("");
    const snackbarColor = ref("");
    const emitter = useEmitter();
    function snackbarHide() {
      emitter.emit("SNACKBAR_HIDE");
    }
    return { snackbar, snackbarMessage, snackbarColor, snackbarHide, emitter };
  },
  mounted() {
    this.emitter.on(
      "SNACKBAR_SHOW",
      ({ message, color }: { message: string; color: string }) => {
        this.snackbar = true;
        this.snackbarMessage = message;
        this.snackbarColor = color;
      }
    );
    this.emitter.on("SNACKBAR_HIDE", () => {
      this.snackbar = false;
      this.snackbarMessage = "";
      this.snackbarColor = "";
    });
  },
});
