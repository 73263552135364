import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";
import "firebase/database";
import { collection, getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getDatabase } from "firebase/database";

// :: Environment Variables
const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DB_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_STORAGE_BUCKET,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_FIREBASE_EVENT,
} = process.env;

// :: Firebase configurations
// :: Client side ENV variables https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
const config = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DB_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
};

const app = initializeApp(config);

// :: Firebase Storage reference
export const storageRef = (path: string) => ref(getStorage(app), path);
// :: Firestore references
// :: Creates reference
export const firestoreDb = getFirestore(app);

export const realtimeDb = getDatabase(app);

// :: Returns a reference for a collection
export const firestore = (ref: any) => collection(firestoreDb, ref);
