
import { app } from "@/config/global";
import { computed, defineComponent } from "vue";
import { mapActions } from "vuex";
import Breakpoints from "@/services/Breakpoints";
export default defineComponent({
  setup() {
    const { type } = Breakpoints();
    const appName = computed(() => {
      return app.name;
    });
    return { appName, type };
  },
  methods: {
    ...mapActions("user", ["signOut"]),
  },
});
