// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";
import { theme } from "@/config/global";

const myCustomLightTheme = {
  dark: false,
  colors: theme.light,
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: { myCustomLightTheme },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
