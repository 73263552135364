import { createI18n, Composer } from "vue-i18n";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

export const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  globalInjection: true,
  messages: {
    en,
    es,
  },
});

export function setI18nLanguage(lang: string) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = lang;
  } else {
    (i18n.global as unknown as Composer).locale.value = lang;
  }
  return lang;
}
export default i18n;
