
import { defineComponent } from "vue";
import Header from "@/components/layout/LayoutHeader.vue";
import NavigationMenu from "@/components/layout/NavigationMenu.vue";
import Snackbar from "./components/layout/LayoutSnackbar.vue";
export default defineComponent({
  components: {
    CoronaHeader: Header,
    CoronaSnackbar: Snackbar,
  },
});
