// globals
const material = {
  background: "#f1f7f9",
  primary: "#3b004a",
  primaryLight: "#ff00a6",
  primaryDark: "#239bd4",
  secondary: "#7d03a2",
  secondaryLight: "#e82727",
  secondaryDark: "#9feb2c",
};
export const theme = {
  material,
  light: {
    primary: material.primary,
    secondary: material.secondary,
    accent: material.primaryLight,
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  },
  dark: {
    primary: "#FF5252",
    secondary: material.secondaryDark,
    accent: material.primary,
    error: "#c50e29",
    info: "#0069c0",
    success: "#087f23",
    warning: "#c79100",
  },
};

// :: App
export const app = {
  name: process.env.VUE_APP_NAME,
  url: process.env.VUE_APP_URL,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
};
